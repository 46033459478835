@import './variables'; /* Asegúrate de importar las variables */
/* Asegúrate de que el contenedor principal ocupe toda la pantalla */
.App {
    background-color: #FFFFFF;  // Fondo blanco
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logo-container {
    margin-bottom: 40px;  // Espacio entre el logo y la fecha
  }
  
  .date-container {
    font-family: 'Roboto Mono', monospace;  // Usa la fuente Roboto Mono
    font-size: 1.5rem;  // Tamaño de la fuente
    color: #555555;  // Color gris suave para no competir con el logo
    text-align: center;
    margin-top: 10px;  // Espacio debajo del logo
  }
  

  
body {
  font-family: $font-family;
  background-color: $primary-color;
  color: white;
}

h1 {
  color: $secondary-color;
}

.container {
  padding: 20px;

  h1 {
    color: #d25634;
    text-align: center; /* Centrando el texto */
  }
}



.countdown {
    margin-top: 20px;
    font-size: 2rem;
    color: #00BFFF; // Color azul neón
    text-align: center;
  }


 // Variables para los colores del logo
$logo-stroke-color: #1dd2ba;  // Color neón verde
$logo-stroke-width: 2px;

// Animación para dibujar las letras del logo
@keyframes drawLetter {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

// Estilos para el logo
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  overflow: visible;
  z-index: 1;

  // Aplicar estilos a las letras del SVG usando sus IDs
  #letter1, #letter2, #letter3, #letter4, #letter5, #letter6, #letter7, 
  #letter8, #letter9, #letter10, #letter11, #letter12, #letter13 {
    stroke: $logo-stroke-color;    // Color del borde del logo
    stroke-width: $logo-stroke-width;
    fill: none;                    // Sin relleno
    stroke-dasharray: 1000;        // Largo total del trazo para animación
    stroke-dashoffset: 1000;       // Comienza fuera de la vista
    animation: drawLetter 2s ease forwards;
  }

  // Añadir retraso en la animación para que cada letra aparezca en secuencia
  #letter2 { animation-delay: 0.2s; }
  #letter3 { animation-delay: 0.4s; }
  #letter4 { animation-delay: 0.6s; }
  #letter5 { animation-delay: 0.8s; }
  #letter6 { animation-delay: 1s; }
  #letter7 { animation-delay: 1.2s; }
  #letter8 { animation-delay: 1.4s; }
  #letter9 { animation-delay: 1.6s; }
  #letter10 { animation-delay: 1.8s; }
  #letter11 { animation-delay: 2s; }
  #letter12 { animation-delay: 2.2s; }
  #letter13 { animation-delay: 2.4s; }
}


.email-container {
    margin-top: 10px;  // Espacio entre la fecha y el correo
    font-family: 'Roboto Mono', monospace;  // Mantener la fuente Roboto Mono
    font-size: 1.2rem;  // Tamaño del texto del correo un poco más pequeño
    color: #000000;  // Texto en color negro para el correo
    text-align: center;
  
    a {
      color: #000000;  // Color del enlace (negro)
      text-decoration: none;  // Sin subrayado para el enlace
    }
  
    a:hover {
      text-decoration: underline;  // Subrayado al pasar el mouse
    }
  }